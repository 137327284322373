<script>

import router from '../../index'
import { authFackMethods } from "@/state/helpers";
export default {
  created() {
    this.logout();
    router.push('/login'); 
  },
  methods: {
    ...authFackMethods
  }
}
</script>